import React, { useState } from "react";

import { PriorAttainmentLevelData, LlddAndHealthProblemsData } from "./data";

const StepTwoComponent = () => {
  const [PriorAttainmentLevel, setPriorAttainmentLevel] = useState("[]");
  const [LlddAndHealthProblems, setLlddAndHealthProblems] = useState("[]");
  const [
    LlddAndHealthProblemsQuestion,
    setLlddAndHealthProblemsQuestion,
  ] = useState(false);

  const [PrimaryDisability, setPrimaryDisability] = useState("");

  const [Error, setError] = useState({
    PriorAttainmentLevel: false,
    LlddAndHealthProblems: false,
    LlddAndHealthProblemsQuestion: false,
    PrimaryDisability: "",
  });

  const HandlePriorAttainmentLevel = (e) => {
    let data = JSON.parse(PriorAttainmentLevel);
    if (e.target.checked) {
      data.push(e.target.value);
    } else {
      data = data.filter((flag) => flag !== e.target.value);
    }
    setPriorAttainmentLevel(JSON.stringify(data));
  };

  const HandleLlddAndHealthProblems = (e) => {
    let data = JSON.parse(LlddAndHealthProblems);
    if (e.target.checked) {
      data.push(e.target.value);
    } else {
      data = data.filter((flag) => flag !== e.target.value);
    }
    setLlddAndHealthProblems(JSON.stringify(data));
  };

  const HandleSubmit = (e) => {
    let flag = 0;
    const level = JSON.parse(PriorAttainmentLevel);
    if (level.length === 0) {
      flag++;
      HandleError("PriorAttainmentLevel", true);
    } else {
      HandleError("PriorAttainmentLevel", false);
    }

    if (LlddAndHealthProblemsQuestion) {
      const problems = JSON.parse(LlddAndHealthProblems);
      if (problems.length === 0) {
        flag++;
        HandleError("LlddAndHealthProblems", true);
      } else {
        HandleError("LlddAndHealthProblems", false);
      }
    } else {
      setLlddAndHealthProblems("[]");
      setPrimaryDisability("");
    }

    if (flag === 0) {
      if (window.confirm("Are you sure you want to go to the next page?")) {
        const Value = {
          PriorAttainmentLevel: JSON.parse(PriorAttainmentLevel),
          LlddAndHealthProblemsQuestion: LlddAndHealthProblemsQuestion,
          LlddAndHealthProblems: JSON.parse(LlddAndHealthProblems),
          PrimaryDisability: PrimaryDisability,
        };
        localStorage.setItem("StepTwo", JSON.stringify(Value));
        window.location.href = "/#/step-three";
      }
    }

    e.preventDefault();
  };

  const HandleError = (Name, Value) => {
    setError((Error) => ({
      ...Error,
      [Name]: Value,
    }));
  };

  return (
    <form autoComplete="off" onSubmit={HandleSubmit}>
      <div className="row">
        <div className="col">
          <h4>Prior Attainment Level</h4>
          <hr className="my-4"></hr>
        </div>
      </div>
      <div className="row">
        {PriorAttainmentLevelData.map((data, key) => {
          return (
            <div key={key} className="col-4 custom-control custom-checkbox">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={key + "_PriorAttainmentLevel"}
                  value={data.value}
                  onChange={HandlePriorAttainmentLevel}
                ></input>
                <label
                  className="custom-control-label"
                  htmlFor={key + "_PriorAttainmentLevel"}
                >
                  {data.name}
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <br></br>
      {Error.PriorAttainmentLevel ? (
        <div className="alert alert-danger" role="alert">
          Please select the Prior Attainment Level
        </div>
      ) : (
        ""
      )}
      <br></br>
      <div className="row">
        <div className="col">
          <h4>LLDD and Health Problems</h4>
          <hr className="my-4"></hr>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="check1"
              name="LlddAndHealthProblemsCheck"
              className="custom-control-input"
              onChange={(e) => {
                setLlddAndHealthProblemsQuestion(true);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="check1">
              I consider myself to have a disability or other support need.
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="check2"
              value="false"
              name="LlddAndHealthProblemsCheck"
              className="custom-control-input"
              defaultChecked
              onChange={(e) => {
                setLlddAndHealthProblemsQuestion(false);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="check2">
              I do not consider myself to have a disability or other support
              need.
            </label>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className={LlddAndHealthProblemsQuestion ? "showing" : "hiding"}>
        <div className="row">
          {LlddAndHealthProblemsData.map((data, key) => {
            return (
              <div key={key} className="col-4 custom-control custom-checkbox">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={key + "_LlddAndHealthProblems"}
                    value={data.value}
                    onChange={HandleLlddAndHealthProblems}
                  ></input>
                  <label
                    className="custom-control-label"
                    htmlFor={key + "_LlddAndHealthProblems"}
                  >
                    {data.name}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        <br></br>
        {Error.LlddAndHealthProblems ? (
          <div className="alert alert-danger" role="alert">
            Please select the disability(s) you have
          </div>
        ) : (
          ""
        )}
        <br></br>
        <div className="row">
          <div className="col form-group">
            <label htmlFor="PrimaryDisability">
              Please indicate out of the above which is your primary learning
              difficulty or disability:
            </label>
            <input
              type="text"
              className={Error.PrimaryDisability + " form-control"}
              aria-describedby="PrimaryDisabilityHelp"
              onChange={(e) => {
                setPrimaryDisability(e.target.value);
              }}
              value={PrimaryDisability}
              id="PrimaryDisability"
              name="PrimaryDisability"
            ></input>
            <small id="PrimaryDisabilityHelp" className="form-text text-muted">
              Please enter your primary learning difficulty
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col form-group">
          <button type="submit" className="btn btn-danger btn-lg float-right">
            Proceed
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepTwoComponent;
