import React, { useState } from "react";

import {
  EmploymentStatusData,
  EmploymentIntensityIndicatorData,
  LengthOfUnemploymentData,
  BenefitStatusIndicatorData,
  CountryOfDomicileData,
  HeTermAccomodationData,
  HighestQualificationOnEntryData,
} from "./data";

const StepThreeComponent = () => {
  const [Value, setValue] = useState({
    EmploymentStatus: "",
    EmploymentIntensityIndicator: "",
    LengthOfUnemployment: "",
    BenefitStatusIndicator: "",
    SelfEmployed: false,
    FullTimeEducation: false,
    CountryOfDomicile: "",
    HeTermAccomodation: "",
    HighestQualificationOnEntry: "",
    HighestQualificationOnEntryText: "",
  });

  const [Error, setError] = useState({
    EmploymentStatus: "",
    EmploymentIntensityIndicator: "",
    LengthOfUnemployment: "",
    BenefitStatusIndicator: "",
    SelfEmployed: "",
    FullTimeEducation: "",
    CountryOfDomicile: "",
    HeTermAccomodation: "",
    HighestQualificationOnEntry: "",
    HighestQualificationOnEntryText: "",
  });

  const HandleValidation = (value, form) => {
    let flag = 0;

    if (value === "EmploymentStatus" || form) {
      if (Value.EmploymentStatus === "") {
        flag++;
        HandleError("EmploymentStatus", "is-invalid");
      } else {
        HandleError("EmploymentStatus", "is-valid");
      }
    }

    if (value === "EmploymentIntensityIndicator" || form) {
      if (
        Value.EmploymentIntensityIndicator === "" &&
        Value.EmploymentStatus === "10 – in paid employment"
      ) {
        flag++;
        HandleError("EmploymentIntensityIndicator", "is-invalid");
      } else {
        HandleError("EmploymentIntensityIndicator", "is-valid");
      }
    }

    if (value === "LengthOfUnemployment" || form) {
      if (
        Value.LengthOfUnemployment === "" &&
        (Value.EmploymentStatus ===
          "11 – not in paid employment, looking for work" ||
          Value.EmploymentStatus ===
            "12 – not in paid employment, not looking for work")
      ) {
        flag++;
        HandleError("LengthOfUnemployment", "is-invalid");
      } else {
        HandleError("LengthOfUnemployment", "is-valid");
      }
    }

    /*
    if (value === "BenefitStatusIndicator" || form) {
      if (Value.BenefitStatusIndicator === "") {
        flag++;
        HandleError("BenefitStatusIndicator", "is-invalid");
      } else {
        HandleError("BenefitStatusIndicator", "is-valid");
      }
    }
    */

    if (value === "CountryOfDomicile" || form) {
      if (Value.CountryOfDomicile === "") {
        flag++;
        HandleError("CountryOfDomicile", "is-invalid");
      } else {
        HandleError("CountryOfDomicile", "is-valid");
      }
    }

    if (value === "HeTermAccomodation" || form) {
      if (Value.HeTermAccomodation === "") {
        flag++;
        HandleError("HeTermAccomodation", "is-invalid");
      } else {
        HandleError("HeTermAccomodation", "is-valid");
      }
    }

    if (value === "HighestQualificationOnEntry" || form) {
      if (Value.HighestQualificationOnEntry === "") {
        flag++;
        HandleError("HighestQualificationOnEntry", "is-invalid");
      } else {
        HandleError("HighestQualificationOnEntry", "is-valid");
      }
    }

    if (value === "HighestQualificationOnEntryText" || form) {
      if (
        Value.HighestQualificationOnEntryText === "" &&
        Value.HighestQualificationOnEntry === "others"
      ) {
        flag++;
        HandleError("HighestQualificationOnEntryText", "is-invalid");
      } else {
        HandleError("HighestQualificationOnEntryText", "is-valid");
      }
    }

    return flag;
  };

  const HandleBlur = (e) => {
    HandleValidation(e.target.name, false);
  };

  const HandleSubmit = (e) => {
    const flag = HandleValidation("", true);

    if (flag === 0) {
      if (window.confirm("Are you sure you want to go to the next page?")) {
        if (
          Value.EmploymentStatus ===
            "11 – not in paid employment, looking for work" ||
          Value.EmploymentStatus ===
            "12 – not in paid employment, not looking for work"
        ) {
          HandleChangeWithKey("EmploymentIntensityIndicator", "");
        } else if (Value.EmploymentStatus === "10 – in paid employment") {
          HandleChangeWithKey("LengthOfUnemployment", "");
        }
        localStorage.setItem("StepThree", JSON.stringify(Value));
        window.location.href = "/#/step-four";
      }
    }
    e.preventDefault();
  };

  const HandleError = (Name, Value) => {
    setError((Error) => ({
      ...Error,
      [Name]: Value,
    }));
  };

  const HandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValue((Value) => ({
      ...Value,
      [name]: value,
    }));
  };

  const HandleChangeWithKey = (target, data) => {
    setValue((Value) => ({
      ...Value,
      [target]: data,
    }));
  };

  return (
    <form autoComplete="off" onSubmit={HandleSubmit}>
      <div className="row">
        <div className="col">
          <h4>Learner Information (as at 28 June 2020)</h4>
          <hr className="my-4"></hr>
        </div>
      </div>

      <div className="row">
        <div className="col-6 form-group is-invalid">
          <label htmlFor="EmploymentStatus">Employment status</label>
          <select
            className={Error.EmploymentStatus + " form-control"}
            aria-describedby="EmploymentStatusHelp"
            id="EmploymentStatus"
            name="EmploymentStatus"
            value={Value.EmploymentStatus}
            onBlur={HandleBlur}
            onChange={HandleChange}
          >
            <option value=""></option>
            {EmploymentStatusData.map((data, key) => {
              return (
                <option key={key} value={data.value}>
                  {data.name}
                </option>
              );
            })}
          </select>
          <small id="EmploymentStatusHelp" className="form-text text-muted">
            Please select your current employment status
          </small>
        </div>

        {Value.EmploymentStatus === "10 – in paid employment" ? (
          <div className="col form-group is-invalid">
            <label htmlFor="EmploymentIntensityIndicator">
              Employed/Self-employed
            </label>
            <select
              className={Error.EmploymentIntensityIndicator + " form-control"}
              aria-describedby="EmploymentIntensityIndicatorHelp"
              id="EmploymentIntensityIndicator"
              name="EmploymentIntensityIndicator"
              value={Value.EmploymentIntensityIndicator}
              onBlur={HandleBlur}
              onChange={HandleChange}
            >
              <option value=""></option>
              {EmploymentIntensityIndicatorData.map((data, key) => {
                return (
                  <option key={key} value={data.value}>
                    {data.name}
                  </option>
                );
              })}
            </select>
            <small
              id="EmploymentIntensityIndicatorHelp"
              className="form-text text-muted"
            >
              Please select the number of hours you work every week
            </small>
          </div>
        ) : (
          ""
        )}
        {Value.EmploymentStatus ===
          "11 – not in paid employment, looking for work" ||
        Value.EmploymentStatus ===
          "12 – not in paid employment, not looking for work" ? (
          <div className="col form-group is-invalid">
            <label htmlFor="LengthOfUnemployment">
              I have been unemployed for:
            </label>
            <select
              className={Error.LengthOfUnemployment + " form-control"}
              aria-describedby="LengthOfUnemploymentHelp"
              id="LengthOfUnemployment"
              name="LengthOfUnemployment"
              value={Value.LengthOfUnemployment}
              onBlur={HandleBlur}
              onChange={HandleChange}
            >
              <option value=""></option>
              {LengthOfUnemploymentData.map((data, key) => {
                return (
                  <option key={key} value={data.value}>
                    {data.name}
                  </option>
                );
              })}
            </select>
            <small
              id="LengthOfUnemploymentHelp"
              className="form-text text-muted"
            >
              Please select the number of months you have been unemployed for
            </small>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col-6 form-group is-invalid">
          <label htmlFor="BenefitStatusIndicator">I am in receipt of</label>
          <select
            className={Error.BenefitStatusIndicator + " form-control"}
            aria-describedby="BenefitStatusIndicatorHelp"
            id="BenefitStatusIndicator"
            name="BenefitStatusIndicator"
            value={Value.BenefitStatusIndicator}
            onBlur={HandleBlur}
            onChange={HandleChange}
          >
            <option value=""></option>
            {BenefitStatusIndicatorData.map((data, key) => {
              return (
                <option key={key} value={data.value}>
                  {data.name}
                </option>
              );
            })}
          </select>
          <small
            id="BenefitStatusIndicatorHelp"
            className="form-text text-muted"
          >
            Please select your current employment status
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col custom-control custom-checkbox">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="SelfEmployed"
              onChange={(e) => {
                HandleChangeWithKey("SelfEmployed", e.target.checked);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="SelfEmployed">
              Are you self Employed?
            </label>
          </div>
        </div>

        <div className="col custom-control custom-checkbox">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="FullTimeEducation"
              onChange={(e) => {
                HandleChangeWithKey("FullTimeEducation", e.target.checked);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="FullTimeEducation">
              Are you in Full-time education?
            </label>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="row">
        <div className="col">
          <h4>Country of Domicile</h4>
          <hr className="my-4"></hr>
        </div>
      </div>
      <div className="row">
        <div className="col form-group is-invalid">
          <label htmlFor="CountryOfDomicile">
            Permanent/home address prior to entry to the course - not
            necessarily your correspondance address
          </label>
          <select
            className={Error.CountryOfDomicile + " form-control"}
            aria-describedby="CountryOfDomicileHelp"
            id="CountryOfDomicile"
            name="CountryOfDomicile"
            value={Value.CountryOfDomicile}
            onBlur={HandleBlur}
            onChange={HandleChange}
          >
            <option value=""></option>
            {CountryOfDomicileData.map((data, key) => {
              return (
                <option key={key} value={data.code}>
                  {data.country}
                </option>
              );
            })}
          </select>
          <small id="CountryOfDomicileHelp" className="form-text text-muted">
            Please select your country of domicile
          </small>
        </div>

        <div className="col form-group is-invalid">
          <label htmlFor="HeTermAccomodation">
            <br></br>Where is your term time accommodation?
          </label>
          <select
            className={Error.HeTermAccomodation + " form-control"}
            aria-describedby="HeTermAccomodationHelp"
            id="HeTermAccomodation"
            name="HeTermAccomodation"
            value={Value.HeTermAccomodation}
            onBlur={HandleBlur}
            onChange={HandleChange}
          >
            <option value=""></option>
            {HeTermAccomodationData.map((data, key) => {
              return (
                <option key={key} value={data.value}>
                  {data.name}
                </option>
              );
            })}
          </select>
          <small id="HeTermAccomodationHelp" className="form-text text-muted">
            Please select your term time accommodation
          </small>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="row">
        <div className="col">
          <h4>Highest qualification on Entry</h4>
          <hr className="my-4"></hr>
        </div>
      </div>

      <div className="row">
        <div className="col-6 form-group is-invalid">
          <label htmlFor="HighestQualificationOnEntry">
            Highest qualification
          </label>
          <select
            className={Error.HighestQualificationOnEntry + " form-control"}
            aria-describedby="HighestQualificationOnEntryHelp"
            id="HighestQualificationOnEntry"
            name="HighestQualificationOnEntry"
            value={Value.HighestQualificationOnEntry}
            onBlur={HandleBlur}
            onChange={HandleChange}
          >
            <option value=""></option>
            {HighestQualificationOnEntryData.map((data, key) => {
              return (
                <option key={key} value={data.code}>
                  {data.name}
                </option>
              );
            })}
          </select>
          <small
            id="HighestQualificationOnEntryHelp"
            className="form-text text-muted"
          >
            Please select your highest qualification at the time of entry
          </small>
        </div>
        {Value.HighestQualificationOnEntry === "others" ? (
          <div className="col form-group">
            <label htmlFor="HighestQualificationOnEntryText">
              Write the name of your highest qualification
            </label>
            <input
              type="text"
              className={
                Error.HighestQualificationOnEntryText + " form-control"
              }
              aria-describedby="HighestQualificationOnEntryTextHelp"
              onChange={HandleChange}
              onBlur={HandleBlur}
              value={Value.HighestQualificationOnEntryText}
              id="HighestQualificationOnEntryText"
              name="HighestQualificationOnEntryText"
            ></input>
            <small
              id="HighestQualificationOnEntryTextHelp"
              className="form-text text-muted"
            >
              Please write the name of your highest qualification at the time of
              entry
            </small>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col form-group">
          <button type="submit" className="btn btn-danger btn-lg float-right">
            Proceed
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepThreeComponent;
