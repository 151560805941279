import React, { useState } from "react";
import Validator from "validator";
import validator from "validator";
import { TitleData, GenderData, EthnicityData } from "./data";

const StepOneComponent = () => {
  const [Value, setValue] = useState({
    Title: "",
    Forename: "",
    Surname: "",
    AddressLine1: "",
    AddressLine2: "",
    DateOfBirth: "",
    Ethnicity: "",
    Nationality: "",
    Gender: "",
    CurrentPostcode: "",
    PostcodePriorEnrolment: "",
    NiNumber: "",
    County: "",
    Telephone: "",
    EmailAddress: "",
    CareLeaver: false,
    Carer: false,
    ResidencyCriteria: true,
  });

  const [Error, setError] = useState({
    Title: "",
    Forename: "",
    Surname: "",
    AddressLine1: "",
    AddressLine2: "",
    DateOfBirth: "",
    Ethnicity: "",
    Nationality: "",
    Gender: "",
    CurrentPostcode: "",
    PostcodePriorEnrolment: "",
    NiNumber: "",
    County: "",
    Telephone: "",
    EmailAddress: "",
    CareLeaver: "",
    Carer: "",
    ResidencyCriteria: "",
  });

  const HandleValidation = (value, form) => {
    let flag = 0;
    if (value === "Title" || form) {
      if (Value.Title === "") {
        flag++;
        HandleError("Title", "is-invalid");
      } else {
        HandleError("Title", "is-valid");
      }
    }

    if (value === "Forename" || form) {
      if (Value.Forename === "") {
        flag++;
        HandleError("Forename", "is-invalid");
      } else {
        HandleError("Forename", "is-valid");
      }
    }

    if (value === "Surname" || form) {
      if (Value.Surname === "") {
        flag++;
        HandleError("Surname", "is-invalid");
      } else {
        HandleError("Surname", "is-valid");
      }
    }

    if (value === "AddressLine1" || form) {
      if (Value.AddressLine1 === "") {
        flag++;
        HandleError("AddressLine1", "is-invalid");
      } else {
        HandleError("AddressLine1", "is-valid");
      }
    }

    if (value === "DateOfBirth" || form) {
      if (Value.DateOfBirth === "") {
        flag++;
        HandleError("DateOfBirth", "is-invalid");
      } else {
        HandleError("DateOfBirth", "is-valid");
      }
    }

    if (value === "Ethnicity" || form) {
      if (Value.Ethnicity === "") {
        flag++;
        HandleError("Ethnicity", "is-invalid");
      } else {
        HandleError("Ethnicity", "is-valid");
      }
    }

    if (value === "Nationality" || form) {
      if (Value.Nationality === "") {
        flag++;
        HandleError("Nationality", "is-invalid");
      } else {
        HandleError("Nationality", "is-valid");
      }
    }

    if (value === "Gender" || form) {
      if (Value.Gender === "") {
        flag++;
        HandleError("Gender", "is-invalid");
      } else {
        HandleError("Gender", "is-valid");
      }
    }

    if (value === "CurrentPostcode" || form) {
      if (Value.Forename === "") {
        flag++;
        HandleError("CurrentPostcode", "is-invalid");
      } else {
        HandleError("CurrentPostcode", "is-valid");
      }
    }

    if (value === "PostcodePriorEnrolment" || form) {
      if (Value.PostcodePriorEnrolment === "") {
        flag++;
        HandleError("PostcodePriorEnrolment", "is-invalid");
      } else {
        HandleError("PostcodePriorEnrolment", "is-valid");
      }
    }

    if (value === "NiNumber" || form) {
      if (
        Value.NiNumber === "" ||
        !validator.isAlphanumeric(validator.blacklist(Value.NiNumber, " "))
      ) {
        flag++;
        HandleError("NiNumber", "is-invalid");
      } else {
        HandleError("NiNumber", "is-valid");
      }
    }

    if (value === "County" || form) {
      if (Value.County === "") {
        flag++;
        HandleError("County", "is-invalid");
      } else {
        HandleError("County", "is-valid");
      }
    }

    if (value === "Telephone" || form) {
      if (Value.Telephone === "") {
        flag++;
        HandleError("Telephone", "is-invalid");
      } else {
        HandleError("Telephone", "is-valid");
      }
    }

    if (value === "EmailAddress" || form) {
      if (Value.EmailAddress === "" || !Validator.isEmail(Value.EmailAddress)) {
        flag++;
        HandleError("EmailAddress", "is-invalid");
      } else {
        HandleError("EmailAddress", "is-valid");
      }
    }
    return flag;
  };

  const HandleBlur = (e) => {
    HandleValidation(e.target.name, false);
  };

  const HandleSubmit = (e) => {
    const flag = HandleValidation("", true);

    if (flag === 0) {
      if (window.confirm("Are you sure you want to go to the next page?")) {
        localStorage.setItem("StepOne", JSON.stringify(Value));
        window.location.href = "/#/step-two";
      }
    }

    e.preventDefault();
  };

  const HandleError = (Name, Value) => {
    setError((Error) => ({
      ...Error,
      [Name]: Value,
    }));
  };

  const HandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValue((Value) => ({
      ...Value,
      [name]: value,
    }));
  };

  const HandleChangeWithKey = (target, data) => {
    setValue((Value) => ({
      ...Value,
      [target]: data,
    }));
  };

  return (
    <form autoComplete="off" onSubmit={HandleSubmit}>
      <div className="row">
        <div className="col">
          <h4>Learner Information</h4>
          <hr className="my-4"></hr>
        </div>
      </div>
      <div className="row">
        <div className="col-2 form-group is-invalid">
          <label htmlFor="Title">Title</label>
          <select
            className={Error.Title + " form-control"}
            aria-describedby="TitleHelp"
            id="Title"
            name="Title"
            value={Value.Title}
            onBlur={HandleBlur}
            onChange={HandleChange}
          >
            <option value=""></option>
            {TitleData.map((data, key) => {
              return (
                <option key={key} value={data}>
                  {data}
                </option>
              );
            })}
          </select>
          <small id="TitleHelp" className="form-text text-muted">
            Please select title
          </small>
        </div>

        <div className="col form-group">
          <label htmlFor="Forename">Learner Forename(s):</label>
          <input
            type="text"
            className={Error.Forename + " form-control"}
            id="Forename"
            name="Forename"
            aria-describedby="ForenameHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.Forename}
          ></input>
          <small id="ForenameHelp" className="form-text text-muted">
            Please enter your official first name (This will be printed on
            certificates)
          </small>
        </div>

        <div className="col form-group">
          <label htmlFor="Surname">Learner Surname</label>
          <input
            type="text"
            className={Error.Surname + " form-control"}
            aria-describedby="surnameHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.Surname}
            id="Surname"
            name="Surname"
          ></input>
          <small id="SurnameHelp" className="form-text text-muted">
            Please enter your official last name (This will be printed on
            certificates)
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col-2 form-group">
          <label htmlFor="Gender">Gender</label>
          <select
            className={Error.Gender + " form-control"}
            aria-describedby="GenderHelp"
            id="Gender"
            name="Gender"
            value={Value.Gender}
            onChange={HandleChange}
            onBlur={HandleBlur}
          >
            <option value=""></option>
            {GenderData.map((data, key) => {
              return (
                <option key={key} value={data}>
                  {data}
                </option>
              );
            })}
          </select>
          <small id="GenderHelp" className="form-text text-muted">
            Please select gender
          </small>
        </div>

        <div className="col form-group">
          <label htmlFor="DateOfBirth">Date of Birth</label>
          <input
            type="text"
            className={Error.DateOfBirth + " form-control"}
            aria-describedby="DateOfBirthHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.DateOfBirth}
            id="DateOfBirth"
            name="DateOfBirth"
          ></input>
          <small id="DateOfBirthHelp" className="form-text text-muted">
            Please select your date of birth (dd/mm/yyyy)
          </small>
        </div>

        <div className="col form-group">
          <label htmlFor="Ethnicity">Ethnicity</label>
          <select
            className={Error.Ethnicity + " form-control"}
            aria-describedby="TitleHelp"
            id="Ethnicity"
            name="Ethnicity"
            value={Value.Ethnicity}
            onChange={HandleChange}
            onBlur={HandleBlur}
          >
            <option value=""></option>
            {EthnicityData.map((data, key) => {
              return (
                <option key={key} value={data}>
                  {data}
                </option>
              );
            })}
          </select>
          <small id="DateOfBirthHelp" className="form-text text-muted">
            Please select your ethnicity
          </small>
        </div>
        <div className="col form-group">
          <label htmlFor="Nationality">Nationality</label>
          <input
            type="text"
            className={Error.Nationality + " form-control"}
            id="Nationality"
            name="Nationality"
            aria-describedby="NationalityHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.Nationality}
          ></input>
          <small id="NationalityHelp" className="form-text text-muted">
            Please enter your nationality
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col form-group">
          <label htmlFor="AddressLine1">Address Line 1</label>
          <input
            type="text"
            className={Error.AddressLine1 + " form-control"}
            id="AddressLine1"
            name="AddressLine1"
            aria-describedby="AddressLine1Help"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.AddressLine1}
          ></input>
          <small id="AddressLine1Help" className="form-text text-muted">
            Please enter the address line one of your current address
          </small>
        </div>
        <div className="col form-group">
          <label htmlFor="AddressLine2">Address Line 2</label>
          <input
            type="text"
            className={Error.AddressLine2 + " form-control"}
            aria-describedby="AddressLine2Help"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.AddressLine2}
            id="AddressLine2"
            name="AddressLine2"
          ></input>
          <small id="AddressLine2Help" className="form-text text-muted">
            Please enter the address line two of your current address
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col form-group">
          <label htmlFor="County">County</label>
          <input
            type="text"
            className={Error.County + " form-control"}
            aria-describedby="CountyHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.County}
            id="County"
            name="County"
          ></input>
          <small id="CountyHelp" className="form-text text-muted">
            Please enter your county
          </small>
        </div>
        <div className="col-3 form-group">
          <label htmlFor="CurrentPostcode">Current Postcode</label>
          <input
            type="text"
            className={Error.CurrentPostcode + " form-control"}
            id="CurrentPostcode"
            name="CurrentPostcode"
            aria-describedby="CurrentPostcodeHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.CurrentPostcode}
          ></input>
          <small id="CurrentPostcodeHelp" className="form-text text-muted">
            Please enter your current postcode
          </small>
        </div>
        <div className="col-3 form-group">
          <label htmlFor="PostcodePriorEnrolment">
            Postcode prior to enrolment
          </label>
          <input
            type="text"
            className={Error.PostcodePriorEnrolment + " form-control"}
            aria-describedby="PostcodePriorEnrolmentHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.PostcodePriorEnrolment}
            id="PostcodePriorEnrolment"
            name="PostcodePriorEnrolment"
          ></input>
          <small
            id="PostcodePriorEnrolmentHelp"
            className="form-text text-muted"
          >
            Please enter postcode prior to enrolment
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col form-group">
          <label htmlFor="NiNumber">National Insurance Number</label>
          <input
            type="text"
            className={Error.NiNumber + " form-control"}
            aria-describedby="NiNumberHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.NiNumber}
            id="NiNumber"
            name="NiNumber"
          ></input>
          <small id="NiNumberHelp" className="form-text text-muted">
            Please enter your national insurance number
          </small>
        </div>
        <div className="col form-group">
          <label htmlFor="Telephone">Telephone</label>
          <input
            type="text"
            className={Error.Telephone + " form-control"}
            aria-describedby="TelephoneHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.Telephone}
            id="Telephone"
            name="Telephone"
          ></input>
          <small id="TelephoneHelp" className="form-text text-muted">
            Please enter your telephone number
          </small>
        </div>
        <div className="col form-group">
          <label htmlFor="EmailAddress">Email Address</label>
          <input
            type="text"
            className={Error.EmailAddress + " form-control"}
            aria-describedby="EmailAddressHelp"
            onChange={HandleChange}
            onBlur={HandleBlur}
            value={Value.EmailAddress}
            id="EmailAddress"
            name="EmailAddress"
          ></input>
          <small id="EmailAddressHelp" className="form-text text-muted">
            Please enter your email address
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col custom-control custom-checkbox">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="CareLeaver"
              onChange={(e) => {
                HandleChangeWithKey("CareLeaver", e.target.checked);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="CareLeaver">
              Have you previously been looked after by the Local Authority?
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col custom-control custom-checkbox">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="Carer"
              onChange={(e) => {
                HandleChangeWithKey("Carer", e.target.checked);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="Carer">
              Are you a carer for someone due to illness, diability, mental
              health or an addiction?
            </label>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="row">
        <div className="col">
          <h4>Residency Criteria</h4>
          <hr className="my-4"></hr>
        </div>
      </div>
      <div className="row">
        <div className="col custom-control custom-checkbox">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="ResidencyCriteria"
              defaultChecked
              onChange={(e) => {
                HandleChangeWithKey("ResidencyCriteria", e.target.checked);
              }}
            ></input>
            <label className="custom-control-label" htmlFor="ResidencyCriteria">
              I have been a full resident in the UK or EEA for a period of 3
              years or more for purposes other than study.
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col form-group">
          <button type="submit" className="btn btn-danger btn-lg float-right">
            Proceed
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepOneComponent;
