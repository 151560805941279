const EmploymentStatusData = [
  {
    name: "in paid employment",
    value: "10 – in paid employment",
  },
  {
    name: "not in paid employment, looking for work",
    value: "11 – not in paid employment, looking for work",
  },
  {
    name: "not in paid employment, not looking for work",
    value: "12 – not in paid employment, not looking for work",
  },
];

const EmploymentIntensityIndicatorData = [
  {
    name: "0 - 10 hrs",
    value: "5 - 0 - 10 hrs",
  },
  {
    name: "11 - 20 hrs",
    value: "6 - 11 - 20 hrs",
  },
  {
    name: "21 - 30 hrs",
    value: "7 - 21 - 30 hrs",
  },
  {
    name: "31+ hrs",
    value: "8 - 31+ hrs",
  },
];

const LengthOfUnemploymentData = [
  { name: "less than 6 months", value: "1 – less than 6 months" },
  { name: "6-11 months", value: "2 – 6-11 months" },
  { name: "12-23 months", value: "3 – 12-23 months" },
  { name: "24-35 months", value: "4 – 24-35 months" },
  { name: "36 months or more", value: "5 – 36 months or more" },
];

const BenefitStatusIndicatorData = [
  { name: "in receipt of JSA", value: "1 – in receipt of JSA" },
  { name: "in receipt of ESA, WRAG", value: "2 – in receipt of ESA, WRAG" },
  {
    name: "in receipt of other state benefit",
    value: "3 – in receipt of other state benefit",
  },
  {
    name: "in receipt of Universal Credit",
    value: "4 – in receipt of Universal Credit",
  },
];

const CountryOfDomicileData = [
  {
    code: "XK",
    country: "United Kingdom, not otherwise specified",
  },
  {
    code: "AX",
    country: "Åland Islands {Ahvenamaa}",
  },
  {
    code: "AF",
    country: "Afghanistan",
  },
  {
    code: "XQ",
    country: "Africa not otherwise specified",
  },
  {
    code: "AL",
    country: "Albania",
  },
  {
    code: "DZ",
    country: "Algeria",
  },
  {
    code: "AS",
    country: "American Samoa",
  },
  {
    code: "AD",
    country: "Andorra",
  },
  {
    code: "AO",
    country: "Angola",
  },
  {
    code: "AI",
    country: "Anguilla",
  },
  {
    code: "XX",
    country: "Antarctica and Oceania not otherwise specified",
  },
  {
    code: "AG",
    country: "Antigua and Barbuda",
  },
  {
    code: "AR",
    country: "Argentina",
  },
  {
    code: "AM",
    country: "Armenia",
  },
  {
    code: "AW",
    country: "Aruba",
  },
  {
    code: "XS",
    country: "Asia (Except Middle East) not otherwise specified",
  },
  {
    code: "AU",
    country: "Australia",
  },
  {
    code: "AT",
    country: "Austria",
  },
  {
    code: "AZ",
    country: "Azerbaijan",
  },
  {
    code: "BS",
    country: "Bahamas, The",
  },
  {
    code: "BH",
    country: "Bahrain",
  },
  {
    code: "BD",
    country: "Bangladesh",
  },
  {
    code: "BB",
    country: "Barbados",
  },
  {
    code: "BY",
    country: "Belarus",
  },
  {
    code: "BE",
    country: "Belgium",
  },
  {
    code: "BZ",
    country: "Belize",
  },
  {
    code: "BJ",
    country: "Benin",
  },
  {
    code: "BM",
    country: "Bermuda",
  },
  {
    code: "BT",
    country: "Bhutan",
  },
  {
    code: "BO",
    country: "Bolivia",
  },
  {
    code: "BQ",
    country: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "BA",
    country: "Bosnia and Herzegovina",
  },
  {
    code: "BW",
    country: "Botswana",
  },
  {
    code: "BR",
    country: "Brazil",
  },
  {
    code: "VG",
    country: "British Virgin Islands [Virgin Islands, British]",
  },
  {
    code: "BN",
    country: "Brunei [Brunei Darussalam]",
  },
  {
    code: "BG",
    country: "Bulgaria",
  },
  {
    code: "BF",
    country: "Burkina [Burkina Faso]",
  },
  {
    code: "MM",
    country: "Burma [Myanmar]",
  },
  {
    code: "BI",
    country: "Burundi",
  },
  {
    code: "KH",
    country: "Cambodia",
  },
  {
    code: "CM",
    country: "Cameroon",
  },
  {
    code: "CA",
    country: "Canada",
  },
  {
    code: "IC",
    country: "Canary Islands",
  },
  {
    code: "CV",
    country: "Cape Verde",
  },
  {
    code: "XW",
    country: "Caribbean not otherwise specified",
  },
  {
    code: "KY",
    country: "Cayman Islands",
  },
  {
    code: "CF",
    country: "Central African Republic",
  },
  {
    code: "XU",
    country: "Central America not otherwise specified",
  },
  {
    code: "TD",
    country: "Chad",
  },
  {
    code: "XL",
    country: "Channel Islands not otherwise specified",
  },
  {
    code: "CL",
    country: "Chile",
  },
  {
    code: "CN",
    country: "China",
  },
  {
    code: "CX",
    country: "Christmas Island",
  },
  {
    code: "CC",
    country: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    country: "Colombia",
  },
  {
    code: "KM",
    country: "Comoros",
  },
  {
    code: "CG",
    country: "Congo",
  },
  {
    code: "CD",
    country:
      "Congo (Democratic Republic) [Congo (The Democratic Republic of the)] {formerly Zaire}",
  },
  {
    code: "CK",
    country: "Cook Islands",
  },
  {
    code: "CR",
    country: "Costa Rica",
  },
  {
    code: "HR",
    country: "Croatia",
  },
  {
    code: "CU",
    country: "Cuba",
  },
  {
    code: "CW",
    country: "Curaçao",
  },
  {
    code: "XA",
    country: "Cyprus (European Union)",
  },
  {
    code: "XB",
    country: "Cyprus (Non-European Union)",
  },
  {
    code: "XC",
    country: "Cyprus not otherwise specified",
  },
  {
    code: "CZ",
    country: "Czech Republic",
  },
  {
    code: "DK",
    country: "Denmark",
  },
  {
    code: "DJ",
    country: "Djibouti",
  },
  {
    code: "DM",
    country: "Dominica",
  },
  {
    code: "DO",
    country: "Dominican Republic",
  },
  {
    code: "TL",
    country: "East Timor [Timor Leste]",
  },
  {
    code: "EC",
    country: "Ecuador",
  },
  {
    code: "EG",
    country: "Egypt",
  },
  {
    code: "SV",
    country: "El Salvador",
  },
  {
    code: "XF",
    country: "England",
  },
  {
    code: "GQ",
    country: "Equatorial Guinea",
  },
  {
    code: "ER",
    country: "Eritrea",
  },
  {
    code: "EE",
    country: "Estonia",
  },
  {
    code: "ET",
    country: "Ethiopia",
  },
  {
    code: "XP",
    country: "Europe not otherwise specified",
  },
  {
    code: "EU",
    country: "European Union not otherwise specified",
  },
  {
    code: "FK",
    country: "Falkland Islands [Falkland Islands (Malvinas)]",
  },
  {
    code: "FO",
    country: "Faroe Islands",
  },
  {
    code: "FJ",
    country: "Fiji",
  },
  {
    code: "FI",
    country: "Finland",
  },
  {
    code: "FR",
    country: "France {includes Corsica}",
  },
  {
    code: "GF",
    country: "French Guiana",
  },
  {
    code: "PF",
    country: "French Polynesia",
  },
  {
    code: "GA",
    country: "Gabon",
  },
  {
    code: "GM",
    country: "Gambia, The",
  },
  {
    code: "GE",
    country: "Georgia",
  },
  {
    code: "DE",
    country: "Germany",
  },
  {
    code: "GH",
    country: "Ghana",
  },
  {
    code: "GI",
    country: "Gibraltar",
  },
  {
    code: "GR",
    country: "Greece",
  },
  {
    code: "GL",
    country: "Greenland",
  },
  {
    code: "GD",
    country: "Grenada",
  },
  {
    code: "GP",
    country: "Guadeloupe",
  },
  {
    code: "GU",
    country: "Guam",
  },
  {
    code: "GT",
    country: "Guatemala",
  },
  {
    code: "GG",
    country: "Guernsey",
  },
  {
    code: "GN",
    country: "Guinea",
  },
  {
    code: "GW",
    country: "Guinea-Bissau",
  },
  {
    code: "GY",
    country: "Guyana",
  },
  {
    code: "HT",
    country: "Haiti",
  },
  {
    code: "HN",
    country: "Honduras",
  },
  {
    code: "HK",
    country: "Hong Kong (Special Administrative Region of China) [Hong Kong]",
  },
  {
    code: "HU",
    country: "Hungary",
  },
  {
    code: "IS",
    country: "Iceland",
  },
  {
    code: "IN",
    country: "India",
  },
  {
    code: "ID",
    country: "Indonesia",
  },
  {
    code: "IR",
    country: "Iran [Iran, Islamic Republic of]",
  },
  {
    code: "IQ",
    country: "Iraq",
  },
  {
    code: "IE",
    country: "Ireland",
  },
  {
    code: "IM",
    country: "Isle of Man",
  },
  {
    code: "IL",
    country: "Israel",
  },
  {
    code: "IT",
    country: "Italy {Includes Sardinia, Sicily}",
  },
  {
    code: "CI",
    country: "Ivory Coast [Côte D'ivoire]",
  },
  {
    code: "JM",
    country: "Jamaica",
  },
  {
    code: "JP",
    country: "Japan",
  },
  {
    code: "JE",
    country: "Jersey",
  },
  {
    code: "JO",
    country: "Jordan",
  },
  {
    code: "KZ",
    country: "Kazakhstan",
  },
  {
    code: "KE",
    country: "Kenya",
  },
  {
    code: "KI",
    country: "Kiribati",
  },
  {
    code: "KP",
    country: "Korea (North) [Korea, Democratic People's Republic of]",
  },
  {
    code: "KR",
    country: "Korea (South) [Korea, Republic of]",
  },
  {
    code: "QO",
    country: "Kosovo",
  },
  {
    code: "KW",
    country: "Kuwait",
  },
  {
    code: "KG",
    country: "Kyrgyzstan",
  },
  {
    code: "LA",
    country: "Laos [Lao People's Democratic Republic]",
  },
  {
    code: "LV",
    country: "Latvia",
  },
  {
    code: "LB",
    country: "Lebanon",
  },
  {
    code: "LS",
    country: "Lesotho",
  },
  {
    code: "LR",
    country: "Liberia",
  },
  {
    code: "LY",
    country: "Libya [Libyan Arab Jamahiriya]",
  },
  {
    code: "LI",
    country: "Liechtenstein",
  },
  {
    code: "LT",
    country: "Lithuania",
  },
  {
    code: "LU",
    country: "Luxembourg",
  },
  {
    code: "MO",
    country: "Macao (Special Administrative Region of China) [Macao]",
  },
  {
    code: "MK",
    country: "Macedonia [Macedonia, The Former Yugoslav Republic of]",
  },
  {
    code: "MG",
    country: "Madagascar",
  },
  {
    code: "MW",
    country: "Malawi",
  },
  {
    code: "MY",
    country: "Malaysia",
  },
  {
    code: "MV",
    country: "Maldives",
  },
  {
    code: "ML",
    country: "Mali",
  },
  {
    code: "MT",
    country: "Malta",
  },
  {
    code: "MH",
    country: "Marshall Islands",
  },
  {
    code: "MQ",
    country: "Martinique",
  },
  {
    code: "MR",
    country: "Mauritania",
  },
  {
    code: "MU",
    country: "Mauritius",
  },
  {
    code: "YT",
    country: "Mayotte",
  },
  {
    code: "MX",
    country: "Mexico",
  },
  {
    code: "FM",
    country: "Micronesia [Micronesia, Federated States of]",
  },
  {
    code: "XR",
    country: "Middle East not otherwise specified",
  },
  {
    code: "MD",
    country: "Moldova [Moldova, Republic of]",
  },
  {
    code: "MC",
    country: "Monaco",
  },
  {
    code: "MN",
    country: "Mongolia",
  },
  {
    code: "ME",
    country: "Montenegro",
  },
  {
    code: "MS",
    country: "Montserrat",
  },
  {
    code: "MA",
    country: "Morocco",
  },
  {
    code: "MZ",
    country: "Mozambique",
  },
  {
    code: "NA",
    country: "Namibia",
  },
  {
    code: "NR",
    country: "Nauru",
  },
  {
    code: "NP",
    country: "Nepal",
  },
  {
    code: "NL",
    country: "Netherlands",
  },
  {
    code: "AN",
    country: "Netherlands Antilles",
  },
  {
    code: "NC",
    country: "New Caledonia",
  },
  {
    code: "NZ",
    country: "New Zealand",
  },
  {
    code: "NI",
    country: "Nicaragua",
  },
  {
    code: "NE",
    country: "Niger",
  },
  {
    code: "NG",
    country: "Nigeria",
  },
  {
    code: "NU",
    country: "Niue",
  },
  {
    code: "NF",
    country: "Norfolk Island",
  },
  {
    code: "XT",
    country: "North America not otherwise specified",
  },
  {
    code: "XG",
    country: "Northern Ireland",
  },
  {
    code: "MP",
    country: "Northern Mariana Islands",
  },
  {
    code: "NO",
    country: "Norway",
  },
  {
    code: "ZZ",
    country: "Not known",
  },
  {
    code: "PS",
    country:
      "Occupied Palestinian Territories [Palestinian Territory, Occupied] {formerly West Bank (including East Jerusalem) and Gaza Strip}",
  },
  {
    code: "OM",
    country: "Oman",
  },
  {
    code: "PK",
    country: "Pakistan",
  },
  {
    code: "PW",
    country: "Palau",
  },
  {
    code: "PA",
    country: "Panama",
  },
  {
    code: "PG",
    country: "Papua New Guinea",
  },
  {
    code: "PY",
    country: "Paraguay",
  },
  {
    code: "PE",
    country: "Peru",
  },
  {
    code: "PH",
    country: "Philippines",
  },
  {
    code: "PN",
    country: "Pitcairn, Henderson, Ducie and Oeno Islands [Pitcairn]",
  },
  {
    code: "PL",
    country: "Poland",
  },
  {
    code: "PT",
    country: "Portugal {includes Madeira, Azores}",
  },
  {
    code: "PR",
    country: "Puerto Rico",
  },
  {
    code: "QA",
    country: "Qatar",
  },
  {
    code: "RE",
    country: "Réunion",
  },
  {
    code: "RO",
    country: "Romania",
  },
  {
    code: "RU",
    country: "Russia [Russian Federation]",
  },
  {
    code: "RW",
    country: "Rwanda",
  },
  {
    code: "WS",
    country: "Samoa",
  },
  {
    code: "SM",
    country: "San Marino",
  },
  {
    code: "ST",
    country: "Sao Tome and Principe",
  },
  {
    code: "SA",
    country: "Saudi Arabia",
  },
  {
    code: "XH",
    country: "Scotland",
  },
  {
    code: "SN",
    country: "Senegal",
  },
  {
    code: "RS",
    country: "Serbia",
  },
  {
    code: "SC",
    country: "Seychelles",
  },
  {
    code: "SL",
    country: "Sierra Leone",
  },
  {
    code: "SG",
    country: "Singapore",
  },
  {
    code: "SX",
    country: "Sint Maarten (Dutch part)",
  },
  {
    code: "SK",
    country: "Slovakia",
  },
  {
    code: "SI",
    country: "Slovenia",
  },
  {
    code: "SB",
    country: "Solomon Islands",
  },
  {
    code: "SO",
    country: "Somalia",
  },
  {
    code: "ZA",
    country: "South Africa",
  },
  {
    code: "XV",
    country: "South America not otherwise specified",
  },
  {
    code: "GS",
    country: "South Georgia and The South Sandwich Islands",
  },
  {
    code: "SS",
    country: "South Sudan",
  },
  {
    code: "ES",
    country: "Spain {includes Ceuta, Melilla}",
  },
  {
    code: "LK",
    country: "Sri Lanka",
  },
  {
    code: "BL",
    country: "St Barthélemy",
  },
  {
    code: "SH",
    country: "St Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "KN",
    country: "St Kitts and Nevis",
  },
  {
    code: "LC",
    country: "St Lucia",
  },
  {
    code: "MF",
    country: "St Martin (French Part) [St Martin]",
  },
  {
    code: "PM",
    country: "St Pierre and Miquelon",
  },
  {
    code: "VC",
    country: "St Vincent and The Grenadines",
  },
  {
    code: "SD",
    country: "Sudan",
  },
  {
    code: "SR",
    country: "Surinam [Suriname]",
  },
  {
    code: "SJ",
    country: "Svalbard and Jan Mayen",
  },
  {
    code: "SZ",
    country: "Swaziland",
  },
  {
    code: "SE",
    country: "Sweden",
  },
  {
    code: "CH",
    country: "Switzerland",
  },
  {
    code: "SY",
    country: "Syria [Syrian Arab Republic]",
  },
  {
    code: "TW",
    country: "Taiwan [Taiwan, Province of China]",
  },
  {
    code: "TJ",
    country: "Tajikistan",
  },
  {
    code: "TZ",
    country: "Tanzania [Tanzania, United Republic of]",
  },
  {
    code: "TH",
    country: "Thailand",
  },
  {
    code: "TG",
    country: "Togo",
  },
  {
    code: "TK",
    country: "Tokelau",
  },
  {
    code: "TO",
    country: "Tonga",
  },
  {
    code: "TT",
    country: "Trinidad and Tobago",
  },
  {
    code: "TN",
    country: "Tunisia",
  },
  {
    code: "TR",
    country: "Turkey",
  },
  {
    code: "TM",
    country: "Turkmenistan",
  },
  {
    code: "TC",
    country: "Turks and Caicos Islands",
  },
  {
    code: "TV",
    country: "Tuvalu",
  },
  {
    code: "UG",
    country: "Uganda",
  },
  {
    code: "UA",
    country: "Ukraine",
  },
  {
    code: "AE",
    country: "United Arab Emirates",
  },
  {
    code: "US",
    country: "United States",
  },
  {
    code: "VI",
    country: "United States Virgin Islands [Virgin Islands, U. S.]",
  },
  {
    code: "UY",
    country: "Uruguay",
  },
  {
    code: "UZ",
    country: "Uzbekistan",
  },
  {
    code: "VU",
    country: "Vanuatu",
  },
  {
    code: "VA",
    country: "Vatican City [Holy See (Vatican City State)]",
  },
  {
    code: "VE",
    country: "Venezuela",
  },
  {
    code: "VN",
    country: "Vietnam [Viet Nam]",
  },
  {
    code: "XI",
    country: "Wales",
  },
  {
    code: "WF",
    country: "Wallis and Futuna",
  },
  {
    code: "EH",
    country: "Western Sahara",
  },
  {
    code: "YE",
    country: "Yemen",
  },
  {
    code: "ZM",
    country: "Zambia",
  },
  {
    code: "ZW",
    country: "Zimbabwe",
  },
];

const HeTermAccomodationData = [
  { name: "Parental/guardian home", value: "2 - Parental/guardian home" },
  { name: "Other", value: "4 - Other" },
  { name: "Not known", value: "5 - Not known" },
  {
    name: "Own residence (owned or rented)",
    value: "7 - Own residence (owned or rented)",
  },
  {
    name: "Other rented accommodation (rent a room shared house)",
    value: "8 - Other rented accommodation (rent a room shared house)",
  },
];

const HighestQualificationOnEntryData = [
  {
    code: "J30",
    name: "Higher National Diploma (HND)",
  },
  {
    code: "C30",
    name: "Higher National Certificate (HNC)",
  },
  {
    code: "C90",
    name: "Undergraduate credits",
  },
  {
    code: "P41",
    name: "Diploma at Level 3",
  },
  {
    code: "P42",
    name: "Certificate at Level 3",
  },
  {
    code: "P46",
    name: "Award at Level 3",
  },
  {
    code: "P62",
    name: "International Baccalaureate Diploma",
  },
  {
    code: "P63",
    name: "International Baccalaureate Certificate",
  },
  {
    code: "other-level-4",
    name: "Other Level 4",
  },
  {
    code: "other-level-3",
    name: "Other Level 3",
  },
  {
    code: "other-level-2",
    name: "Other Level 2",
  },
  {
    code: "other-level-1",
    name: "Other Level 1",
  },
  {
    code: "X02",
    name:
      "Mature student admitted on basis of previous experince and admissions test",
  },
  {
    code: "others",
    name: "Others",
  },
];

const ContactedPurposeData = [
  "About courses or learning opportunities",
  "For surveys and research",
  "By post",
  "By phone",
  "By email",
];

const EthnicityData = [
  "31 - English/Welsh/Scottish/N Irish/British",
  "32 - Irish",
  "33 - Gypsy or Irish Traveller",
  "34 - Any Other White background",
  "35 - White and Black Caribbean",
  "36 - White and Black African",
  "37 - White and Asian",
  "38 - Any Other Mixed/Multiple ethnic background",
  "39 - Indian",
  "40 - Pakistani",
  "41 - Bangladeshi",
  "42 - Chinese",
  "43 - Any Other Asian background",
  "44 - African",
  "45 - Caribbean",
  "46 - Any other Black/African/Caribbean background",
  "47 - Arab",
  "Any other ethnic group",
];

const TitleData = ["Mr", "Ms"];

const GenderData = ["Male", "Female", "Other"];

const PriorAttainmentLevelData = [
  { name: "Entry level", value: "9 - Entry level" },
  {
    name: "Other qualifications below level 1",
    value: "7 - Other qualifications below level 1",
  },
  {
    name: "Level 1 (e.g. GCSE D-G/1-3)",
    value: "1 - Level 1 (e.g. GCSE D-G/1-3)",
  },
  {
    name: "Full level 2 (e.g. GCSE A-C/9-4/A Level)",
    value: "2 - Full level 2 (e.g. GCSE A-C/9-4/A Level)",
  },
  {
    name: "Full level 3 (e.g. A Level, 2 or more)",
    value: "3 - Full level 3 (e.g. A Level, 2 or more)",
  },
  {
    name: "Level 4 (e.g. HNC/NVQ/CertEd)",
    value: "10 - Level 4 (e.g. HNC/NVQ/CertEd)",
  },
  {
    name: "Level 5 (e.g. FdA/HND)",
    value: "11 - Level 5 (e.g. FdA/HND)",
  },
  { name: "Level 6 (e.g. BA/BSc)", value: "12 - Level 6 (e.g. BA/BSc)" },
  {
    name: "Level 7 and above (e.g. masters)",
    value: "13 - Level 7 and above (e.g. masters)",
  },
  { name: "No qualifications", value: "99 - No qualifications" },
];

const LlddAndHealthProblemsData = [
  { name: "Visual Impairment", value: "4 - Visual Impairment" },
  { name: "Hearing Impairment", value: "5 - Hearing Impairment" },
  {
    name: "Disability affecting mobility",
    value: "6 - Disability affecting mobility",
  },
  {
    name: "Profound complex disabilities",
    value: "7 - Profound complex disabilities",
  },
  {
    name: "Social and emotional difficulties",
    value: "8 - Social and emotional difficulties",
  },
  {
    name: "Mental health difficulty",
    value: "9 - Mental health difficulty",
  },
  {
    name: "Moderate learning difficulty",
    value: "10 - Moderate learning difficulty",
  },
  {
    name: "Severe learning difficulty",
    value: "11 - Severe learning difficulty",
  },
  { name: "Dyslexia", value: "12 - Dyslexia" },
  { name: "13 - Dyscalculia", value: "13 - Dyscalculia" },
  {
    name: "Autism spectrum disorder",
    value: "14 - Autism spectrum disorder",
  },
  { name: "Asperger’s syndrome", value: "15 - Asperger’s syndrome" },
  {
    name: "Temporary disability after illness",
    value: "16 - Temporary disability after illness",
  },
  {
    name: "Speech, Language & Communication Needs",
    value: "17 - Speech, Language and Communication Needs",
  },
  {
    name: "Other physical disability",
    value: "93 - Other physical disability",
  },
  {
    name: "Other specific learning difficulty",
    value: "94 - Other specific learning difficulty",
  },
  {
    name: "Other medical condition",
    value: "95 - Other medical condition",
  },
  {
    name: "Other learning difficulty",
    value: "96 - Other learning difficulty",
  },
  { name: "Other disability", value: "97 - Other disability" },
  { name: "Prefer not to say", value: "98 - Prefer not to say" },
  { name: "Not Provided", value: "99 - Not Provided" },
];

export {
  EmploymentStatusData,
  EmploymentIntensityIndicatorData,
  LengthOfUnemploymentData,
  BenefitStatusIndicatorData,
  CountryOfDomicileData,
  HeTermAccomodationData,
  HighestQualificationOnEntryData,
  ContactedPurposeData,
  EthnicityData,
  TitleData,
  GenderData,
  PriorAttainmentLevelData,
  LlddAndHealthProblemsData,
};
