import React from "react";

const StepFiveComponent = () => {
  return (
    <div className="row">
      <div className="col">
        <h4>Thank you for your registration</h4>
        <hr className="my-4"></hr>
        <p>We have recorded your data. Thank you</p>
      </div>
    </div>
  );
};

export default StepFiveComponent;
