import React from "react";

import lsclondon from "../assets/images/lsclondon.svg";
import ncdurham from "../assets/images/ncdurham.svg";

const HeaderComponent = () => {
  return (
    <header className="container">
      <div>
        <img src={ncdurham} alt="New College Durham Logo"></img>
      </div>
      <h1 className="display-4">
        HE Data Capture Form<br></br>
        <span>New College Durham</span>
      </h1>
      <div>
        <img src={lsclondon} alt="London School of Commerce Logo"></img>
      </div>
    </header>
  );
};

export default HeaderComponent;
