import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import "../scss/app.scss";

import StepOneComponent from "./step-one";
import StepTwoComponent from "./step-two";
import StepThreeComponent from "./step-three";
import StepFourComponent from "./step-four";
import StepFiveComponent from "./step-five";
import HeaderComponent from "./header";

const IndexComponent = () => {
  return (
    <>
      <HeaderComponent></HeaderComponent>
      <div className="container IndexComponent">
        <Router>
          <Switch>
            <Route path="/" exact component={StepOneComponent}></Route>
            <Route path="/step-two" exact component={StepTwoComponent}></Route>
            <Route
              path="/step-three"
              exact
              component={StepThreeComponent}
            ></Route>
            <Route
              path="/step-four"
              exact
              component={StepFourComponent}
            ></Route>
            <Route
              path="/step-five"
              exact
              component={StepFiveComponent}
            ></Route>
          </Switch>
        </Router>
      </div>
    </>
  );
};

export default IndexComponent;
