import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import IndexComponent from "./components/index";
import PrintFormComponent from "./components/print-form";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route
            path="/print-form/:course/:student"
            component={PrintFormComponent}
          ></Route>
          <Route path="/" component={IndexComponent}></Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
