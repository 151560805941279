import React, { useState } from "react";

import { ContactedPurposeData } from "./data";

import base from "./airtable";

const StepFourComponent = () => {
  const [ContactedPurpose, setContactedPurpose] = useState("[]");

  const HandleContactedPurpose = (e) => {
    let data = JSON.parse(ContactedPurpose);
    if (e.target.checked) {
      data.push(e.target.value);
    } else {
      data = data.filter((flag) => flag !== e.target.value);
    }
    setContactedPurpose(JSON.stringify(data));
  };

  const HandleSubmit = (e) => {
    if (window.confirm("Are you sure you want to submit the form?")) {
      const Value = {
        ContactedPurpose: JSON.parse(ContactedPurpose),
      };
      localStorage.setItem("StepFour", JSON.stringify(Value));

      const StepOne = JSON.parse(localStorage.getItem("StepOne"));
      const StepTwo = JSON.parse(localStorage.getItem("StepTwo"));
      const StepThree = JSON.parse(localStorage.getItem("StepThree"));
      const StepFour = JSON.parse(localStorage.getItem("StepFour"));

      const data = Object.assign(StepOne, StepTwo, StepThree, StepFour);

      for (const i in data) {
        if (data[i] === "" || data[i] === null) {
          delete data[i];
        }
      }

      localStorage.setItem("Data", JSON.stringify(data));

      base("Registration").create(
        [
          {
            fields: data,
          },
        ],
        function (err, records) {
          if (err) {
            return;
          }
          records.forEach(function (record) {
            base("Registration").update(
              [
                {
                  id: record.id,
                  fields: {
                    FDABM:
                      "https://registration.lsclondon.co.uk/#/print-form/FDABM/" +
                      record.id,
                    FDASIHSC:
                      "https://registration.lsclondon.co.uk/#/print-form/FDASIHSC/" +
                      record.id,
                  },
                },
              ],
              function (err, records) {
                records.forEach(function (record) {
                  window.location.href = "/#/step-five";
                });
              }
            );
          });
        }
      );
    }

    e.preventDefault();
  };

  return (
    <form autoComplete="off" onSubmit={HandleSubmit}>
      <div className="row">
        <div className="col">
          <h4>Declaration</h4>
          <hr className="my-4"></hr>
        </div>
      </div>
      <h5>Enrolment Information</h5>
      <p>
        This document is your Learning Agreement with New College Durham (NCD).
        It states the main qualification(s) for which you are aiming. You must
        intend to complete the full qualification(s) and make yourself familiar
        with the NCD Regulations which are set out in Course Handbooks or made
        available via the NCD website. The College's Academic Appeals Policy can
        be found in the Student Handbook, full details of which are available{" "}
        <a
          href="https://www.newcollegedurham.ac.uk/higher-education/studying/course-handbooks/he-information"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . Your enrolment as a student is subject to the College's regulations on
        fees and charges as set out in the Fees Policy available{" "}
        <a
          href="http://www.newcollegedurham.ac.uk/the-college/fees-and-funding/fees-and-how-to-pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </p>
      <h5>Enrolment Privacy Notice</h5>
      <p>
        New College Durham will store, use and share the information you have
        provided on this Enrolment Form for the purposes stated below and with
        any third parties mentioned. We will also share your data on an ad-hoc
        basis if we are required to by law. If you want to update or remove any
        of the personal data you have provided you can make a request to do so
        by contacting the London School of Commerce (LSC). If you require more
        information regarding our Privacy Notice, please see{" "}
        <a
          href="https://www.newcollegedurham.ac.uk/privacynotices/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
      <p>
        Most of the information collected at enrolment is required by us to
        deliver your course and to prove you are studying with us. To do this
        and to fulfil our contract with you we will share information about you
        with our Funding Providers, Validating and Awarding Bodies,
        sub-contracted service providers and with any of our partner
        institutions that are contracted to deliver your course(s). If we are
        asked by a local authority to confirm that you are studying with us we
        will only do so if we are required by law or government authority.
      </p>
      <p>
        We will retain data that is required by New College Durham and our
        Funding Providers for 6 years after your course ends in order to
        administrate your enrolment contract with us. We will retain some data
        indefinitely as a permanent record to show who you are, when you
        attended, what your assessment marks were and what awards you got. This
        will help us to supply you with a basic reference or transcript if you
        request one as part of our public task. If you tick to receive marketing
        and market research information from us on the enrolment form we (or our
        funding partners) may contact you about courses or for feedback on your
        experience at College.
      </p>
      <h5>
        ESFA &amp; the Learner Records Service - How We Use Your Personal
        Information
      </h5>
      <p>
        This privacy notice is issued by the Education and Skills Funding Agency
        (ESFA), on behalf of the Secretary of State for the Department of
        Education (DfE). It is to inform learners how their personal information
        will be used by the DfE, the ESFA (an executive agency of the DfE) and
        any successor bodies to these organisations. For the purposes of
        relevant data protection legislation, the DfE is the data controller for
        personal data processed by the ESFA. Your personal information is used
        by the DfE to exercise its functions and to meet its statutory
        responsibilities, including under the Apprenticeships, Skills, Children
        and Learning Act 2009 and to create and maintain a unique learner number
        (ULN) and a personal learning record (PLR). Your information will be
        securely destroyed after it is no longer required for these
        purposes.Your information may be used for education, training,
        employment and well-being related purposes, including for research. The
        DfE and the English European Social Fund (ESF) Managing Authority (or
        agents acting on their behalf) may contact you in order for them to
        carry out research and evaluation to inform the effectiveness of
        training. Your information may also be shared with other third parties
        for the above purposes, but only where the law allows it and the sharing
        is in compliance with data protection legislation. Further information
        about use of and access to your personal data, details of organisations
        with whom we regularly share data, information about how long we retain
        your data, and how to change your consent to being contacted, please
        visit{" "}
        <a
          href="https://www.gov.uk/government/publications/esfa-privacy-notice"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . By signing this agreement, you confirm that you have read and
        understood the ‘Acceptable Use Policy: Schoology’ which can be accessed{" "}
        <a
          href="http://www.newcollegedurham.ac.uk/download/File/0/o0vvdu.pdf/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
      <h5>Tuition Fees</h5>
      <p>
        Your enrolment is subject to the College’s regulations on fees and
        charges as set out in the College Tuition Fee and Refund and
        Compensation Policies available{" "}
        <a
          href="http://www.newcollegedurham.ac.uk/the-college/fees-and-funding/fees-and-how-to-pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . Please ensure you have read and understood these documents. You will
        not receive your certificates if you have not paid all of your fees and
        charges. The fees and charges will remain your responsibility until
        payment or evidence of fee responsibility is provided. If your fees are
        paid by the Student Loans Company you will be personally liable for all
        fees if evidence of payment is not received by week 3 of your course
        start date.
      </p>
      <h5>Withdrawals</h5>
      <p>
        It is the student’s responsibility to formally notify LSC of their
        withdrawal at the point at which they leave the course. Any refund or
        outstanding fees due will be subject to New College Durham’s Refund and
        Compensation Policy. Failure to inform LSC in a timely manner could
        result in the student incurring full fee responsibility for the course.
      </p>
      <h5>Equality, Diversity and Inclusion</h5>
      <p>
        New College Durham is committed to equality of opportunity for all which
        it believes is essential to good education and employment. It is our
        policy to ensure learning and working exists in an environment that
        promotes equality of opportunity, celebrates diversity and allows
        everyone to achieve to their fullest potential.
      </p>
      <h5>Safeguarding</h5>
      <p>
        Safeguarding and promoting the welfare of learners may be defined as
        protecting them from maltreatment, preventing impairment of their health
        or development, and ensuring that they are able to undertake their
        studies in a safe and effective environment. Safeguarding and promoting
        the welfare of learners is everyone’s responsibility; learners must be
        safe and feel safe. Any learner who feels unsafe - or feels that another
        learner may be feeling unsafe - must make their concerns known to their
        tutor or a Designated Safeguarding Lead immediately.
      </p>

      <div className="row">
        <div className="col">
          <h5>
            You can agree to be contacted for other purposes by ticking any of
            the following boxes:
          </h5>
        </div>
      </div>
      <div className="row">
        {ContactedPurposeData.map((data, key) => {
          return (
            <div key={key} className="col-4 custom-control custom-checkbox">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={key + "_ContactedPurpose"}
                  value={data}
                  onChange={HandleContactedPurpose}
                ></input>
                <label
                  className="custom-control-label"
                  htmlFor={key + "_ContactedPurpose"}
                >
                  {data}
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <br></br>
      <br></br>
      <div className="row">
        <div className="col form-group">
          <div className="alert alert-danger" role="alert">
            By submitting this application you agree to the above terms &amp;
            you certify that the information contained on this form is correct.
          </div>

          <button type="submit" className="btn btn-danger btn-lg float-right">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepFourComponent;
