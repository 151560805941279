import React, { useState, useEffect } from "react";

import base from "./airtable";

import "../scss/print.scss";

import lsclondon from "../assets/images/lsclondon.svg";
import ncdurham from "../assets/images/ncdurham.svg";

import {
  PriorAttainmentLevelData,
  LlddAndHealthProblemsData,
  LengthOfUnemploymentData,
  EmploymentIntensityIndicatorData,
  BenefitStatusIndicatorData,
  ContactedPurposeData,
  HeTermAccomodationData,
  HighestQualificationOnEntryData,
} from "./data";
import { useParams } from "react-router-dom";

const FDABM = {
  date: "28-Jun-20",
  code: "1044399",
  name: "FdA Business and Management",
  postcode: "SE1 6NP",
  start: "29-Jun-20",
  end: "25-Mar-22",
  elq: "2",
  year: "2",
  completion: "3",
  fee: "£9,000",
  fte: "100",
  special: "0",
  loan: "",
};

const FDASIHSC = {
  date: "29-Jun-20",
  code: "1044403",
  name: "FdA Supporting Innovation in Health and Social Care",
  postcode: "SE1 6NP",
  start: "29-Jun-20",
  end: "25-Mar-22",
  elq: "2",
  year: "2",
  completion: "3",
  fee: "£9,000",
  fte: "100",
  special: "0",
  loan: "",
};

const PrintFormComponent = () => {
  const { course, student } = useParams();
  const [Course] = useState(course === "FDABM" ? FDABM : FDASIHSC);
  const [Value, setValue] = useState({});

  useEffect(() => {
    base("Registration").find(student, (err, record) => {
      setValue(record.fields);
    });
  }, [student, Value]);

  return Value.Forename ? (
    <div className="PrintForm">
      <section className="PageOne page">
        <div className="header">
          <div>
            <h1>
              HE Data Capture Form<br></br>New College Durham 2019/20
            </h1>
          </div>
          <div>
            <img src={lsclondon} alt="London School of Commerce Logo"></img>
          </div>
          <div>
            <img src={ncdurham} alt="New College Durham Logo"></img>
          </div>
        </div>
        <div
          className="simple"
          style={{ textAlign: "right", padding: "10px 0" }}
        >
          This name will appear on your certificate and must match the Student
          Loans Company
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "110px 200px 180px 1fr",
            gridGap: "10px",
          }}
        >
          <div>Learner Ref: </div>
          <div>
            <input type="text"></input>
          </div>
          <div>Learner Surname: </div>
          <div>
            <input type="text" defaultValue={Value.Surname}></input>
          </div>
          <div>Learner Title: </div>
          <div>
            <input type="text" defaultValue={Value.Title}></input>
          </div>
          <div>Learner Forename(s): </div>
          <div>
            <input type="text" defaultValue={Value.Forename}></input>
          </div>
        </div>
        <hr></hr>
        <h2>
          Learner Information - Please Complete in BLOCK CAPITALS Using Black
          Ink
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "140px 380px 20px 130px 1fr",
            gridGap: "10px",
          }}
        >
          <div>Current Address: </div>
          <div>
            <input type="text" defaultValue={Value.AddressLine1}></input>
          </div>
          <div></div>
          <div>DOB: </div>
          <div>
            <input type="text" defaultValue={Value.DateOfBirth}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "530px 20px 130px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            <input type="text" defaultValue={Value.AddressLine2}></input>
          </div>
          <div></div>
          <div>Gender: </div>
          <div>
            <input type="text" defaultValue={Value.Gender}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "140px 380px 20px 130px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>NI Number: </div>
          <div>
            <input type="text" defaultValue={Value.NiNumber}></input>
          </div>
          <div></div>
          <div>Ethinicity: </div>
          <div>
            <input type="text" defaultValue={Value.Ethnicity}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "240px 280px 20px 130px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>Current Postcode: </div>
          <div>
            <input type="text" defaultValue={Value.CurrentPostcode}></input>
          </div>
          <div></div>
          <div>Nationality: </div>
          <div>
            <input type="text" defaultValue={Value.Nationality}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "240px 280px 20px 130px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>Postcode prior to enrolment: </div>
          <div>
            <input
              type="text"
              defaultValue={Value.PostcodePriorEnrolment}
            ></input>
          </div>
          <div></div>
          <div>Telephone: </div>
          <div>
            <input type="text" defaultValue={Value.Telephone}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "70px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>Email: </div>
          <div>
            <input type="text" defaultValue={Value.EmailAddress}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "140px 100px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>Care Leaver?</div>
          <div>
            <input
              type="text"
              defaultValue={Value.CareLeaver === true ? "Yes" : "No"}
            ></input>
          </div>
          <div style={{ textAlign: "right" }}>
            <strong>
              Have you previously been looked after by the Local Authority?
            </strong>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "140px 100px 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>Carer?</div>
          <div>
            <input
              type="text"
              defaultValue={Value.Carer === "checked" ? "Yes" : "No"}
            ></input>
          </div>
          <div style={{ textAlign: "right" }}>
            <strong>
              Are you a carer for someone due to illness, diability, mental
              health or an addiction?
            </strong>
          </div>
        </div>
        <hr></hr>
        <h2>Residency Criteria</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 100px",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            I have been a full resident in the UK or EEA for a period of 3 years
            or more for purposes other than study.
          </div>
          <div>
            <input
              type="text"
              defaultValue={Value.ResidencyCriteria === true ? "Yes" : "No"}
            ></input>
          </div>
        </div>
        <hr></hr>
        <h2>Prior Attainment Level</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            marginTop: "5px",
          }}
        >
          {PriorAttainmentLevelData.map((data, key) => {
            const string = Value.PriorAttainmentLevel;
            return (
              <div
                key={key}
                style={{
                  display: "grid",
                  gridTemplateColumns: "25px 1fr",
                  gridGap: "10px",
                  marginTop: "5px",
                }}
              >
                <div>
                  <input
                    type="text"
                    defaultValue={
                      string ? (string.includes(data.value) ? "Y" : "") : ""
                    }
                    style={{ textAlign: "center" }}
                  ></input>
                </div>
                <div style={{ fontSize: "14px" }}>{data.name}</div>
              </div>
            );
          })}
        </div>
        <hr></hr>
        <h2>LLDD and Health Problems</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 100px",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            I consider myself to have a disability or other support need.
            (Please tick the box(es) which apply to you below).
          </div>
          <div>
            <input
              type="text"
              defaultValue={
                Value.LlddAndHealthProblemsQuestion === true ? "Y" : ""
              }
            ></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 100px",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            I do not consider myself to have a disability or other support need.
          </div>
          <div>
            <input
              type="text"
              defaultValue={
                Value.LlddAndHealthProblemsQuestion === true ? "" : "Y"
              }
            ></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            marginTop: "5px",
          }}
        >
          {LlddAndHealthProblemsData.map((data, key) => {
            const string = Value.LlddAndHealthProblems;
            return (
              <div
                key={key}
                style={{
                  display: "grid",
                  gridTemplateColumns: "25px 1fr",
                  gridGap: "10px",
                  marginTop: "5px",
                }}
              >
                <div>
                  <input
                    type="text"
                    defaultValue={
                      string ? (string.includes(data.value) ? "Y" : "") : ""
                    }
                    style={{ textAlign: "center" }}
                  ></input>
                </div>
                <div style={{ fontSize: "14px" }}>{data.name}</div>
              </div>
            );
          })}
        </div>
        <hr></hr>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "10px",
          }}
        >
          <div>
            Please indicate out of the above which is your primary learning
            difficulty or disability:
          </div>
          <input type="text" defaultValue={Value.PrimaryDisability}></input>
        </div>
      </section>
      <section className="page">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "110px 200px 180px 1fr",
            gridGap: "10px",
          }}
        >
          <div>Learner Ref: </div>
          <div>
            <input type="text"></input>
          </div>
          <div>Learner Name: </div>
          <div>
            <input
              type="text"
              defaultValue={Value.Forename + " " + Value.Surname}
            ></input>
          </div>
        </div>
        <hr></hr>
        <h2>
          Employment status - (Must be before the start date of the programme)
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 400px",
            gridGap: "10px",
          }}
        >
          <div>Date status applies from:</div>
          <input type="text" defaultValue={Course.date}></input>
        </div>
        <div>
          <strong>
            Prior enrolment I was? (select form the options below)
          </strong>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 400px",
            gridGap: "10px",
            marginTop: "5px",
          }}
        >
          <div>
            <strong>Not In Paid Employment</strong> (looking for and available
            to work)<br></br>
            <strong> I have been unemployed for:</strong>
            <br></br>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "10px",
                marginTop: "5px",
              }}
            >
              {LengthOfUnemploymentData.map((data, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "25px 1fr",
                      gridGap: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        defaultValue={
                          Value.LengthOfUnemployment === data.value ? "Y" : ""
                        }
                        style={{ textAlign: "center" }}
                      ></input>
                    </div>
                    <div>{data.name}</div>
                  </div>
                );
              })}
            </div>
            <br></br>
            <strong>I am in receipt of:</strong>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "10px",
                marginTop: "5px",
              }}
            >
              {BenefitStatusIndicatorData.map((data, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "25px 1fr",
                      gridGap: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        defaultValue={
                          Value.BenefitStatusIndicator === data.value ? "Y" : ""
                        }
                        style={{ textAlign: "center" }}
                      ></input>
                    </div>
                    <div>{data.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <strong>
              <br></br>Employed/Self-employed (please tick below)
            </strong>

            {EmploymentIntensityIndicatorData.map((data, key) => {
              return (
                <div
                  key={key}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "25px 1fr",
                    gridGap: "10px",
                    marginTop: "5px",
                  }}
                >
                  <div>
                    <input
                      type="text"
                      defaultValue={
                        Value.EmploymentIntensityIndicator === data.value
                          ? "Y"
                          : ""
                      }
                      style={{ textAlign: "center" }}
                    ></input>
                  </div>
                  <div>{data.name}</div>
                </div>
              );
            })}
            <br></br>
            <br></br>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "25px 1fr",
                gridGap: "10px",
                marginTop: "5px",
              }}
            >
              <div>
                <input
                  type="text"
                  defaultValue={Value.SelfEmployed === true ? "Y" : ""}
                  style={{ textAlign: "center" }}
                ></input>
              </div>
              <div>Are you self Employed?</div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "25px 1fr",
                gridGap: "10px",
                marginTop: "5px",
              }}
            >
              <div>
                <input
                  type="text"
                  defaultValue={Value.FullTimeEducation === true ? "Y" : ""}
                  style={{ textAlign: "center" }}
                ></input>
              </div>
              <div>In Full-time education?</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 200px",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            Not in Paid employment (not looking for, and /or not available to
            start work (e.g. retired):
          </div>
          <input
            type="text"
            defaultValue={
              Value.EmploymentStatus ===
              "11 – not in paid employment, looking for work"
                ? "Y"
                : ""
            }
          ></input>
        </div>
        <hr></hr>
        <h2>Course Information</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "200px 1fr 200px 200px",
            gridGap: "10px",
          }}
        >
          <div>
            <input type="text" defaultValue={Course.code}></input>
          </div>
          <div>
            <strong style={{ fontSize: "24px" }}>{Course.name}</strong>
          </div>
          <div>Del Location Postcode</div>
          <div>
            <input type="text" defaultValue={Course.postcode}></input>
          </div>
        </div>
        <p>
          Dates can be updated if incorrect. Please cross through date and write
          new date underneath
        </p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto 1fr auto 1fr",
            gridGap: "15px",
          }}
        >
          <div>Start Date</div>
          <div>
            <input type="text" defaultValue={Course.start}></input>
          </div>
          <div>Planned End Date: </div>
          <div>
            <input type="text" defaultValue={Course.end}></input>
          </div>
          <div>HE Equivalent of Lower Qual (ELQ)</div>
          <div>
            <input type="text" defaultValue={Course.elq}></input>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto 1fr auto 1fr",
            gridGap: "15px",
            marginTop: "15px",
          }}
        >
          <div>Type of Instance Year</div>
          <div>
            <input type="text" defaultValue={Course.year}></input>
          </div>
          <div>Completion of Year of Instance </div>
          <div>
            <input type="text" defaultValue={Course.completion}></input>
          </div>
          <div>Tuition Fee</div>
          <div>
            <input type="text" defaultValue={Course.fee}></input>
          </div>
          <div>Student Instance FTE</div>
          <div>
            <input type="text" defaultValue={Course.special}></input>
          </div>
          <div>Special Fee Indicator</div>
          <div>
            <input type="text" defaultValue={Course.fte}></input>
          </div>
          <div>Paying via SLC (Student Loan)</div>
          <div>
            <input type="text" defaultValue={Course.loan}></input>
          </div>
        </div>
        <hr></hr>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 200px",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            <strong>
              Country of Domicile (permanent/home address prior to entry to the
              course - not necessarily your correspondance address)
            </strong>
          </div>
          <input type="text" defaultValue={Value.CountryOfDomicile}></input>
        </div>
        <hr></hr>
        <p>
          <strong>Where is your term time accommodation?</strong> please select:
        </p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            marginTop: "5px",
          }}
        >
          {HeTermAccomodationData.map((data, key) => {
            const string = Value.HeTermAccomodation;
            return (
              <div
                key={key}
                style={{
                  display: "grid",
                  gridTemplateColumns: "25px 1fr",
                  gridGap: "10px",
                  marginTop: "5px",
                }}
              >
                <div>
                  <input
                    type="text"
                    defaultValue={
                      string ? (string.includes(data.value) ? "Y" : "") : ""
                    }
                    style={{ textAlign: "center" }}
                  ></input>
                </div>
                <div>{data.name}</div>
              </div>
            );
          })}
        </div>
        <hr></hr>
        <h2>Highest Qual on Entry</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            marginTop: "5px",
          }}
        >
          {HighestQualificationOnEntryData.map((data, key) => {
            const string = Value.HighestQualificationOnEntry;
            return (
              <div
                key={key}
                style={{
                  display: "grid",
                  gridTemplateColumns: "25px 1fr",
                  gridGap: "10px",
                  marginTop: "5px",
                }}
              >
                <div>
                  <input
                    type="text"
                    defaultValue={
                      string !== "undefined" || string !== ""
                        ? string.includes(data.code)
                          ? "Y"
                          : ""
                        : ""
                    }
                    style={{ textAlign: "center" }}
                  ></input>
                </div>
                <div style={{ fontSize: "16px" }}>{data.name}</div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "10px",
            marginTop: "10px",
          }}
        >
          <div>
            <strong>
              If not above then please write your highest qulaification:
            </strong>
          </div>
          <input
            type="text"
            defaultValue={Value.HighestQualificationOnEntryText}
          ></input>
        </div>
      </section>
      <section className="page declaration">
        <h2>Declaration</h2>
        <h5>Enrolment Information</h5>
        <p>
          This document is your Learning Agreement with New College Durham
          (NCD). It states the main qualification(s) for which you are aiming.
          You must intend to complete the full qualification(s) and make
          yourself familiar with the NCD Regulations which are set out in Course
          Handbooks or made available via the NCD website. The College's
          Academic Appeals Policy can be found in the Student Handbook, full
          details of which are available{" "}
          <a
            href="https://www.newcollegedurham.ac.uk/higher-education/studying/course-handbooks/he-information"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          . Your enrolment as a student is subject to the College's regulations
          on fees and charges as set out in the Fees Policy available{" "}
          <a
            href="http://www.newcollegedurham.ac.uk/the-college/fees-and-funding/fees-and-how-to-pay/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </p>
        <h5>Enrolment Privacy Notice</h5>
        <p>
          New College Durham will store, use and share the information you have
          provided on this Enrolment Form for the purposes stated below and with
          any third parties mentioned. We will also share your data on an ad-hoc
          basis if we are required to by law. If you want to update or remove
          any of the personal data you have provided you can make a request to
          do so by contacting the London School of Commerce (LSC). If you
          require more information regarding our Privacy Notice, please see{" "}
          <a
            href="https://www.newcollegedurham.ac.uk/privacynotices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          Most of the information collected at enrolment is required by us to
          deliver your course and to prove you are studying with us. To do this
          and to fulfil our contract with you we will share information about
          you with our Funding Providers, Validating and Awarding Bodies,
          sub-contracted service providers and with any of our partner
          institutions that are contracted to deliver your course(s). If we are
          asked by a local authority to confirm that you are studying with us we
          will only do so if we are required by law or government authority.
        </p>
        <p>
          We will retain data that is required by New College Durham and our
          Funding Providers for 6 years after your course ends in order to
          administrate your enrolment contract with us. We will retain some data
          indefinitely as a permanent record to show who you are, when you
          attended, what your assessment marks were and what awards you got.
          This will help us to supply you with a basic reference or transcript
          if you request one as part of our public task. If you tick to receive
          marketing and market research information from us on the enrolment
          form we (or our funding partners) may contact you about courses or for
          feedback on your experience at College.
        </p>
        <h5>
          ESFA &amp; the Learner Records Service - How We Use Your Personal
          Information
        </h5>
        <p>
          This privacy notice is issued by the Education and Skills Funding
          Agency (ESFA), on behalf of the Secretary of State for the Department
          of Education (DfE). It is to inform learners how their personal
          information will be used by the DfE, the ESFA (an executive agency of
          the DfE) and any successor bodies to these organisations. For the
          purposes of relevant data protection legislation, the DfE is the data
          controller for personal data processed by the ESFA. Your personal
          information is used by the DfE to exercise its functions and to meet
          its statutory responsibilities, including under the Apprenticeships,
          Skills, Children and Learning Act 2009 and to create and maintain a
          unique learner number (ULN) and a personal learning record (PLR). Your
          information will be securely destroyed after it is no longer required
          for these purposes.Your information may be used for education,
          training, employment and well-being related purposes, including for
          research. The DfE and the English European Social Fund (ESF) Managing
          Authority (or agents acting on their behalf) may contact you in order
          for them to carry out research and evaluation to inform the
          effectiveness of training. Your information may also be shared with
          other third parties for the above purposes, but only where the law
          allows it and the sharing is in compliance with data protection
          legislation. Further information about use of and access to your
          personal data, details of organisations with whom we regularly share
          data, information about how long we retain your data, and how to
          change your consent to being contacted, please visit{" "}
          <a
            href="https://www.gov.uk/government/publications/esfa-privacy-notice"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          . By signing this agreement, you confirm that you have read and
          understood the ‘Acceptable Use Policy: Schoology’ which can be
          accessed{" "}
          <a
            href="http://www.newcollegedurham.ac.uk/download/File/0/o0vvdu.pdf/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
        <h5>Tuition Fees</h5>
        <p>
          Your enrolment is subject to the College’s regulations on fees and
          charges as set out in the College Tuition Fee and Refund and
          Compensation Policies available{" "}
          <a
            href="http://www.newcollegedurham.ac.uk/the-college/fees-and-funding/fees-and-how-to-pay/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          . Please ensure you have read and understood these documents. You will
          not receive your certificates if you have not paid all of your fees
          and charges. The fees and charges will remain your responsibility
          until payment or evidence of fee responsibility is provided. If your
          fees are paid by the Student Loans Company you will be personally
          liable for all fees if evidence of payment is not received by week 3
          of your course start date.
        </p>
        <h5>Withdrawals</h5>
        <p>
          It is the student’s responsibility to formally notify LSC of their
          withdrawal at the point at which they leave the course. Any refund or
          outstanding fees due will be subject to New College Durham’s Refund
          and Compensation Policy. Failure to inform LSC in a timely manner
          could result in the student incurring full fee responsibility for the
          course.
        </p>
        <h5>Equality, Diversity and Inclusion</h5>
        <p>
          New College Durham is committed to equality of opportunity for all
          which it believes is essential to good education and employment. It is
          our policy to ensure learning and working exists in an environment
          that promotes equality of opportunity, celebrates diversity and allows
          everyone to achieve to their fullest potential.
        </p>
        <h5>Safeguarding</h5>
        <p>
          Safeguarding and promoting the welfare of learners may be defined as
          protecting them from maltreatment, preventing impairment of their
          health or development, and ensuring that they are able to undertake
          their studies in a safe and effective environment. Safeguarding and
          promoting the welfare of learners is everyone’s responsibility;
          learners must be safe and feel safe. Any learner who feels unsafe - or
          feels that another learner may be feeling unsafe - must make their
          concerns known to their tutor or a Designated Safeguarding Lead
          immediately.
        </p>
        <div>
          You can agree to be contacted for other purposes by ticking any of the
          following boxes:
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "400px 1fr 1fr",
            marginTop: "5px",
          }}
        >
          {ContactedPurposeData.map((data, key) => {
            const string = Value.ContactedPurpose;
            return (
              <div
                key={key}
                style={{
                  display: "grid",
                  gridTemplateColumns: "25px 1fr",
                  gridGap: "10px",
                  marginTop: "5px",
                }}
              >
                <div>
                  <input
                    type="text"
                    defaultValue={
                      string ? (string.includes(data) ? "Y" : "") : ""
                    }
                    style={{ textAlign: "center" }}
                  ></input>
                </div>
                <div>{data}</div>
              </div>
            );
          })}
        </div>
        <div>
          <br></br>
          <strong>
            I certify that the information contained on this form is correct.
          </strong>
        </div>
        <br></br>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "150px 1fr 70px 200px",
            gridGap: "10px",
          }}
        >
          <div>Learner Signature: </div>
          <div>
            <input type="text"></input>
          </div>
          <div>Date: </div>
          <div>
            <input type="text"></input>
          </div>
        </div>
      </section>
    </div>
  ) : (
    ""
  );
};

export default PrintFormComponent;
